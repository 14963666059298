<template>
  <div class="addon-group">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AddonGroup',

  data () {
    return {

    }
  }
}
</script>

<style lang="scss">
</style>
